import React, { createContext, useState, useEffect } from "react";

const TemporalDataContext = createContext({});

export function TemporalDataProvider({ children }) {
  const [temporalData, setTemporalData] = useState({
    id: null,
    title: "",
    shortDescription: "",
    largeDescription: "",
    images: [],
    technologies: [],
    isBlocked: false,
    createdAt: "",
    updateAt: "",
  });

  useEffect(() => {
    console.log("TemporalDataProvider: ", temporalData);
  }, [temporalData]);

  return (
    <TemporalDataContext.Provider value={{ temporalData, setTemporalData }}>
      {children}
    </TemporalDataContext.Provider>
  );
}

export default TemporalDataContext;
