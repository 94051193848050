import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { I18nContext } from "../context/I18nProvider";

import Home from "../containers/Home/Home";
import About from "../containers/About/About";
import WorkDetails from "../containers/Works/WorkDetails";

const Routes = ({ supportedLanguages }) => {
  const { language } = useContext(I18nContext);

  return (
    <>
      <Switch>
        <Route
          exact
          path={["/", "/:lang/home", "/:lang/inicio"]}
          component={Home}
        />

        <Route path={["/:lang/about", "/:lang/sobre-mi"]} component={About} />

        <Route path={["/:lang/works", "/:lang/trabajos"]} component={Home} />

        <Route
          path={["/:lang/works/:id", "/:lang/trabajos/:id"]}
          component={Home}
        />

        <Route path={["/:lang/contact", "/:lang/contacto"]} component={Home} />

        <Route
          exact
          path="/:lang/"
          render={(props) =>
            supportedLanguages.includes(props.match.params.lang) ? (
              <Home {...props} />
            ) : (
              <Redirect to={`/${language}/${props.match.params.lang}`} />
            )
          }
        />

        <Route>
          <Redirect to={`/${language}/`} />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
