/* eslint-disable no-unused-vars */
import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

import { I18nProvider } from "../context/I18nProvider";

import Layout from "../layout/Layout";
import GeneralLoader from "../containers/Loaders/GeneralLoader";
import { TemporalDataProvider } from "../context/TempralDataContext";

import Router from "./Router";

const BASE_URL = process.env.REACT_APP_BASENAME_URL;

function App() {
  return (
    <Suspense fallback={<GeneralLoader />}>
      <BrowserRouter basename={BASE_URL}>
        <I18nProvider>
          <TemporalDataProvider>
            <Layout>
              <Router />
            </Layout>
          </TemporalDataProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
