const useAnimations = (elements) => {
  if (elements.length) {
    if ("IntersectionObserver" in window) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        for (let index = 0; index < entries.length; index++) {
          if (entries[index].isIntersecting) {
            entries[index].target.classList.add("in-view");
            observer.unobserve(entries[index].target);
          } else {
            entries[index].target.classList.remove("in-view");
          }
        }
      }, options);

      //observamos
      for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.classList.add("on");
        observer.observe(element);
      }
    }
  }
};

export default useAnimations;
