/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { useHistory, NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { I18nContext } from "../../context/I18nProvider";

import Translate from "../../i18n/Translate";

import Logo from "../../assets/images/static/logo.png";

const Header = ({ language, transparent }) => {
  const { setLanguage, actualComponent } = useContext(I18nContext);
  const { t } = useTranslation();
  const history = useHistory();

  let path = t(`routes.${actualComponent}`);

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  useEffect(() => {
    if (actualComponent !== null && actualComponent !== undefined) {
      history.replace(`${path}`);
    }
  }, [actualComponent, history, path]);

  return (
    <header className={transparent ? "header transparent" : "header"}>
      <div className="header__container">
        <Link to={`/${language}${t("routes.home")}`} className="header__logo">
          <img loading="auto" src={Logo} alt="Tower Sports" />
        </Link>

        <nav className="header__links">
          <NavLink exact to={`/${language}${t("routes.home")}`}>
            <Translate langkey="links.home" />
            <span></span>
          </NavLink>

          <NavLink to={`/${language}${t("routes.about")}`}>
            <Translate langkey="links.about" />
            <span></span>
          </NavLink>

          <NavLink to={`/${language}${t("routes.works")}`}>
            <Translate langkey="links.works" />
            <span></span>
          </NavLink>

          <a
            href="https://links.kevcoder.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Translate langkey="links.contact" />
            <span></span>
          </a>

          <NavLink to="/blog">
            Blog
            <span></span>
          </NavLink>

          <button
            data-active={language === "es" ? true : false}
            onClick={() => changeLanguage("es")}
          >
            ES
          </button>
          <button
            data-active={language === "en" ? true : false}
            onClick={() => changeLanguage("en")}
          >
            EN
          </button>
        </nav>
      </div>
    </header>
  );
};

export default Header;
