/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { I18nContext } from "../../context/I18nProvider";
import useAnimations from "../../hooks/useAnimations";
import useScrollToTop from "../../hooks/useScrollToTop";

import Translate from "../../i18n/Translate";

const About = () => {
  const [elements, setElements] = useState([]);
  const { actualComponent, setActualComponent } = useContext(I18nContext);
  const { t } = useTranslation();

  const root = document.querySelector("#root");

  useAnimations(elements);
  useScrollToTop();

  // Seteamos el componente actual, para las traducción de rutas
  useEffect(() => {
    setActualComponent("about");
  }, [setActualComponent]);

  useEffect(() => {
    const animations = root.querySelectorAll(".animated");

    if (animations) {
      setElements(animations);
    }
  }, []);

  return (
    <section className="abouts">
      <div className="abouts__container animated slide-up">
        <h2 className="animated reveal-text-up">
          <Translate langkey="home.about.container1.title" />
        </h2>

        <div className="abouts__container__text">
          <p className="animated reveal-text-up">
            <Translate langkey="home.about.container1.text1" />
          </p>

          <p className="animated reveal-text-up">
            <Translate langkey="home.about.container1.text2" />
          </p>

          <p className="animated reveal-text-up">
            <Translate langkey="home.about.container1.text3" />
          </p>
        </div>
      </div>

      <div className="abouts__slide animated slide-up">
        <div className="abouts__slide__rss">
          <p>
            <span className="left">
              {" "}
              <Translate langkey="about.slide.line1" />{" "}
            </span>
            <span className="left2">
              {" "}
              <Translate langkey="about.slide.line1" />{" "}
            </span>
          </p>

          <p>
            <span className="right">
              {" "}
              <Translate langkey="about.slide.line2" />{" "}
            </span>
            <span className="right2">
              {" "}
              <Translate langkey="about.slide.line2" />{" "}
            </span>
          </p>

          <p>
            <span className="left">
              {" "}
              <Translate langkey="about.slide.line3" />{" "}
            </span>
            <span className="left2">
              {" "}
              <Translate langkey="about.slide.line3" />{" "}
            </span>
          </p>
        </div>
      </div>

      <div className="abouts__container">
        <h2 className="animated reveal-text-up">
          <Translate langkey="about.skills.title" />
        </h2>

        <div className="abouts__container__text">
          <p className="animated reveal-text-up">
            <Translate langkey="about.skills.text1" />
          </p>

          <p className="animated reveal-text-up">
            <Translate langkey="about.skills.text2" />
          </p>

          <p className="animated reveal-text-up">
            <Translate langkey="about.skills.text3" />
          </p>

          <p className="animated reveal-text-up">
            <Translate langkey="about.skills.text4" />
          </p>

          <p className="animated reveal-text-up">
            <Translate langkey="about.skills.text5" />
          </p>

          <p className="animated reveal-text-up">
            <Translate langkey="about.skills.text6" />
          </p>

          <h3 className="animated reveal-text-up">
            <Translate langkey="about.skills.text7" />
          </h3>
          <ul className="animated reveal-text-up list">
            <li className="animated slide-up">
              <Translate langkey="about.skills.text8" />
            </li>
          </ul>

          <h3 className="animated reveal-text-up">
            <Translate langkey="about.skills.text9" />
          </h3>
          <ul className="animated reveal-text-up list">
            <li className="animated slide-up">BEM</li>

            <li className="animated slide-up">SMACSS</li>

            <li className="animated slide-up">ITCSS</li>

            <li className="animated slide-up">
              <Translate langkey="about.skills.text10" />
            </li>

            <li className="animated slide-up">
              <Translate langkey="about.skills.text11" />
            </li>
          </ul>

          <h3 className="animated reveal-text-up">
            <Translate langkey="about.skills.text12" />
          </h3>
          <ul className="animated reveal-text-up">
            <li className="animated slide-up">UI</li>

            <li className="animated slide-up">UX</li>

            <li className="animated slide-up">
              <Translate langkey="about.skills.text13" />
            </li>

            <li className="animated slide-up">
              {" "}
              <Translate langkey="about.skills.text14" />
            </li>

            <li className="animated slide-up">Design Review</li>

            <li className="animated slide-up">Wireframes</li>

            <li className="animated slide-up">Prototipado</li>

            <li className="animated slide-up">User research</li>

            <li className="animated slide-up">
              {" "}
              <Translate langkey="about.skills.text15" />
            </li>

            <li className="animated slide-up">User Testing</li>

            <li className="animated slide-up">A/B Testing</li>

            <li className="animated slide-up">
              Photoshop{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Illustrator{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>

            <li className="animated slide-up">
              Adobe XD{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Figma{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              Storybook{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>
          </ul>

          <h3 className="animated reveal-text-up">
            <Translate langkey="about.skills.text16" />
          </h3>
          <ul className="animated reveal-text-up">
            <li className="animated slide-up">
              Html{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              CSS{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              JavaScript (ES6+){" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Git / GitHub{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              NPM / Yarn{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Sass / Less / Stylus{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Webpack{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Gulp{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              Rollup{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>

            <li className="animated slide-up">
              React{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Angular <small>(Fundamentos)</small>
            </li>

            <li className="animated slide-up">
              Vue.js{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>

            <li className="animated slide-up">
              Svelte{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>

            <li className="animated slide-up">
              Redux{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              Styled Components{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              CSS Modules{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Styled JSX{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Web Components{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              Tailwind CSS{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Bootstrap{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Material UI{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              Foundation{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              Jest + Enzyme{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              react-testing-library{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              PWA's{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              TypeScript <small>(Fundamentos)</small>
            </li>

            <li className="animated slide-up">
              NodeJS{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              Firebase{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              PostgresSQL{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>

            <li className="animated slide-up">
              PostgresSQL{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>

            <li className="animated slide-up">
              ExpressJS{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>

            <li className="animated slide-up">
              Postman{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Insomnia{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Docker{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>

            <li className="animated slide-up">
              WPO{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>
          </ul>

          <h3 className="animated reveal-text-up">
            <Translate langkey="about.skills.text17" />
          </h3>
          <ul className="animated reveal-text-up">
            <li className="animated slide-up">
              Spark AR{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Threejs{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Three React Fiber{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              React Spring{" "}
              <small>
                (<Translate langkey="about.skills.advanced" />)
              </small>
            </li>

            <li className="animated slide-up">
              Blender{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>

            <li className="animated slide-up">
              Spline{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>

            <li className="animated slide-up">
              Adobe Aero{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>

            <li className="animated slide-up">
              WebXR{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              WebGL{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>

            <li className="animated slide-up">
              WebVR{" "}
              <small>
                (<Translate langkey="about.skills.intermediate" />)
              </small>
            </li>
          </ul>

          <h3 className="animated reveal-text-up">
            <Translate langkey="about.skills.text18" />
          </h3>
          <ul className="animated reveal-text-up list">
            <li className="animated slide-up">
              Java SE{" "}
              <small>
                (<Translate langkey="about.skills.basic" />)
              </small>
            </li>
          </ul>
        </div>
      </div>

      <div className="abouts__copy animated slide-up">
        <p className="animated slide-up">
          "<Translate langkey="about.copy" />"
          <br />
          <span className="animated slide-up">- Kevin Parra</span>
        </p>
      </div>
    </section>
  );
};

export default About;
