import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Menu from "../Menu/Menu";
import MenuIcon from "../../components/Icons/MenuIcon";

import Logo from "../../assets/images/static/logo.png";

const HeaderMobile = ({ language }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { t } = useTranslation();

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header className="header">
        <div className="header__container">
          <Link to={`/${language}${t("routes.home")}`} className="header__logo">
            <img loading="auto" src={Logo} alt="Tower Sports" />
          </Link>

          <div className="header__menu">
            <MenuIcon
              setIsMenuOpen={setIsMenuOpen}
              isMenuOpen={isMenuOpen}
              handleMenuClick={handleMenuClick}
            />
          </div>
        </div>
      </header>
      <Menu
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        handleMenuClick={handleMenuClick}
      />
    </>
  );
};

export default HeaderMobile;
