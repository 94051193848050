import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import Color from "color-thief-react";

import Translate from "../../i18n/Translate";

const WorksLists = ({ index: i, item, data, handleClick }) => {
  return (
    <>
      <li
        id={`item-${item.id}`}
        data-active={data.id === item.id ? "active" : "false"}
        className="works__projects__item animated reveal-text-up"
      >
        <div className="works__projects__item__container">
          <div className="works__projects__item-texts">
            <span>{i < 9 ? `0${i + 1}` : i + 1}</span>{" "}
            <h3>
              {item.title} <br />
              <small>{item.shortDescription}</small>
            </h3>
          </div>

          <p className="background-text">{i < 9 ? `0${i + 1}` : i + 1}</p>

          <div className="works__projects__item-image">
            <img src={item.images[0]} alt="" />

            <Color src={item.images[0]} crossOrigin="anonymous" format="hex">
              {({ data, loading, error }) => {
                if (loading) {
                  return <div className="works__projects__item-overlay" />;
                } else {
                  return (
                    <div
                      className="works__projects__item-overlay"
                      style={{ backgroundColor: data }}
                    />
                  );
                }
              }}
            </Color>
          </div>

          <a
            href={`#item-${item.id}`}
            onClick={(e) => handleClick(e, item)}
            className="callToActions animated reveal-text-up"
          >
            <span></span>
            <Translate langkey="links.seeProject" />
            <HiArrowNarrowRight />
          </a>
        </div>
      </li>
    </>
  );
};

export default WorksLists;
