import React from "react";

import Logo from "../../assets/images/static/logo.png";

const GeneralLoader = () => {
  return (
    <section className="generalLoader">
      <img src={Logo} alt="" />
    </section>
  );
};

export default GeneralLoader;
