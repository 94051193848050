/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Translate from "../../i18n/Translate";

import { I18nContext } from "../../context/I18nProvider";

import MenuIcon from "../../components/Icons/MenuIcon";

const Menu = ({ isMenuOpen, setIsMenuOpen, handleMenuClick }) => {
  const { language } = useContext(I18nContext);
  const { setLanguage, actualComponent } = useContext(I18nContext);

  const { t } = useTranslation();
  const history = useHistory();

  let path = t(`routes.${actualComponent}`);

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  useEffect(() => {
    if (actualComponent !== null && actualComponent !== undefined) {
      history.replace(`${path}`);
    }
  }, [actualComponent, history, path]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenuOpen]);

  return (
    <section className={isMenuOpen ? "menu openend" : "menu closed"}>
      <div className="menu__container isVisible">
        <span></span>
        <div className="menu__container__button">
          <p>
            <Translate langkey="globals.close" />
          </p>
          <MenuIcon
            setIsMenuOpen={setIsMenuOpen}
            isMenuOpen={isMenuOpen}
            handleMenuClick={handleMenuClick}
          />
        </div>
      </div>

      <div className="menu__content">
        {/* Rutas */}
        <nav className="menu__content__links">
          <NavLink
            to={`/${language}${t("routes.home")}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Translate langkey="links.home" />
          </NavLink>

          <NavLink
            to={`/${language}${t("routes.about")}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Translate langkey="links.about" />
          </NavLink>

          <NavLink
            to={`/${language}${t("routes.works")}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Translate langkey="links.works" />
          </NavLink>

          <a
            href="https://links.kevcoder.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Translate langkey="links.contact" />
            <span></span>
          </a>

          <p>
            <button
              data-active={language === "es" ? true : false}
              onClick={() => changeLanguage("es")}
            >
              ES
            </button>
            <button
              data-active={language === "en" ? true : false}
              onClick={() => changeLanguage("en")}
            >
              EN
            </button>
          </p>
        </nav>
      </div>
    </section>
  );
};

export default Menu;
