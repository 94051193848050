/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const I18nContext = createContext({});

export function I18nProvider({ children }) {
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));
  const localStorageLang = localStorage.getItem("i18nextLng");
  const [actualComponent, setActualComponent] = useState(null);

  const { i18n } = useTranslation();

  useEffect(() => {
    console.log(actualComponent);
  }, [actualComponent]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  useEffect(() => {
    setLanguage(localStorage.getItem("i18nextLng"));
  }, [localStorageLang]);

  return (
    <I18nContext.Provider
      value={{ language, setLanguage, actualComponent, setActualComponent }}
    >
      {children}
    </I18nContext.Provider>
  );
}
