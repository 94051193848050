import React, { useEffect, useContext } from "react";

import TemporalDataContext from "../context/TempralDataContext";
import { I18nContext } from "../context/I18nProvider";

import useWindowWidth from "../hooks/useWindowWidth";

// import Cursor from "../components/Cursor";
import HeaderMobile from "./Header/HeaderMobile";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ModalWorks from "../components/Works/ModalWorks";

const Layout = ({ children }) => {
  const { language } = useContext(I18nContext);
  const { temporalData, setTemporalData } = useContext(TemporalDataContext);

  const width = useWindowWidth();

  useEffect(() => {
    // alert(
    //   "Hi, how are you?\n\nI'm currently doing a remodel to my portfolio, if you want to take a look.\n\nYou can contact me via email or Linkedin\n\n visit: links.kevinparralopez.com for my other networks...\n\n see you soon."
    // );
  }, []);

  return (
    <>
      {/* <Cursor /> */}
      {width > 768 ? (
        <Header
          language={language}
          transparent={temporalData.id ? true : false}
        />
      ) : (
        <HeaderMobile language={language} />
      )}

      {temporalData.id ? <ModalWorks data={temporalData} setTemporalData={setTemporalData} /> : null}

      {children}
      <Footer language={language} />
    </>
  );
};

export default Layout;
