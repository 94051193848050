import React from "react";
import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";

import Translate from "../../i18n/Translate";
import useAnimations from "../../hooks/useAnimations";

const Footer = ({ language }) => {
  const { t } = useTranslation();

  const elements = document.querySelectorAll(".animated");
  useAnimations(elements);

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__container__text">
          <h4 className="animated reveal-text-up">
            <Translate langkey="footer.title" />
          </h4>

          <p className="animated reveal-text-up">
            <Translate langkey="footer.copy" />
          </p>

          <a
            href="https://links.kevcoder.co/"
            target="_blank"
            rel="noopener noreferrer"
            className="callToActions negative animated reveal-text-up"
          >
            <span></span>
            <Translate langkey="footer.button" />
            <HiArrowNarrowRight />
          </a>
        </div>

        <span className="animated reveal-text-up">
          <sup>©</sup> Kevin Parra López 2022 | @kev.coder
        </span>
      </div>
    </footer>
  );
};

export default Footer;
