/* eslint-disable jsx-a11y/anchor-is-valid */
// Librerias
import React from "react";
import { BiLinkExternal, BiArrowBack } from "react-icons/bi";

// Contextos

// Hooks

// Componentes

// Imagenes

// Estilos

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const ModalWorks = ({ data, setTemporalData }) => {
  // Estados

  // Contextos

  // Hooks

  // Funciones
  const handleClose = () => {
    setTemporalData({ id: null });
    document.body.style.overflow = "auto";
  };

  // UseEffects

  return (
    <section className="modalworks">
      <button className="modalworks__back" onClick={handleClose}>
        <BiArrowBack />
      </button>

      <div className="modalworks__header">
        <div className="modalworks__image">
          <img src={data.images[0]} alt="" />
        </div>

        <div className="modalworks__title">
          <h2>{data.title}</h2>

          <div className="modalworks__line"></div>
        </div>
      </div>

      <div className="modalworks__container">
        <div className="modalworks__rows">
          <h3>Sobre este proyecto:</h3>
          <p>{data.shortDescription}</p>
          <br />
          {data.isBlocked ? (
            <button disabled>Url del proyecto no disponible</button>
          ) : (
            <a href={data.url} target="_blank" rel="noopener noreferrer">
              Ver proyecto <BiLinkExternal />
            </a>
          )}
        </div>

        <div className="modalworks__rows">
          <h3>Que se hizo:</h3>
          <p>{data.largeDescription}</p>
        </div>

        <div className="modalworks__rows">
          <h3>Tecnologias que se usaron:</h3>
          <ul>
            {data.technologies.map((item, index) => (
              <li key={index}>
                <p>{item}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className="modalworks__rows">
          <h3>Imagenes del producto final:</h3>
          {data.images.map((item, index) => (
            <img key={index} src={item} alt="" />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ModalWorks;
