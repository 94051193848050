/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";

import { I18nContext } from "../../context/I18nProvider";
import useAnimations from "../../hooks/useAnimations";

import Translate from "../../i18n/Translate";
import WorksLists from "../../components/Home/WorksLists";
import TemporalDataContext from "../../context/TempralDataContext";

import PortinosLogo from "../../assets/images/static/Portinos.png";
import IntelLogo from "../../assets/images/static/Intel.png";
import SenaLogo from "../../assets/images/static/Sena.png";
import KcumenLogo from "../../assets/images/static/Kcumen.svg";
import OnuLogo from "../../assets/images/static/Onu.png";
import HemavLogo from "../../assets/images/static/logoHemav.png";
import ArcorLogo from "../../assets/images/static/arcor.png";

import temporalWorks from "../../jsons/temporalData.json";

const Home = () => {
  const [elements, setElements] = useState([]);

  const { temporalData, setTemporalData } = useContext(TemporalDataContext);
  const { setActualComponent } = useContext(I18nContext);

  const { t } = useTranslation();
  const works = useRef(null);

  const root = document.querySelector("#root");

  // Enviamos todsa la info hacia el modal
  const handleClick = (e, item) => {
    e.preventDefault();

    const paddinTop = window.innerWidth > 768 ? 200 : 120;

    const href = e.target?.getAttribute("href");

    if (href) {
      const offsetTop =
        works.current?.querySelector(href).offsetTop - paddinTop;

      window.scroll({
        top: offsetTop,
        behavior: "smooth",
      });

      if (item.id) {
        setTemporalData(item);
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  };

  useAnimations(elements);

  // Seteamos el componente actual, para las traduccion de rutas
  useEffect(() => {
    setActualComponent("home");
  }, [setActualComponent]);

  useEffect(() => {
    const animations = root.querySelectorAll(".animated");

    if (animations) {
      setElements(animations);
    }
  }, []);

  return (
    <>
      <main className="main">
        <div className="main__hero">
          <span className="animated slide-up">
            <Translate langkey="home.hero.title" />
          </span>
          <h1>
            <Translate langkey="home.hero.subtitle" />
          </h1>
          <p>
            <Translate langkey="home.hero.copy" />
          </p>

          <div className="main__hero__description">
            <p>
              <Translate langkey="home.hero.description" />
            </p>

            <Link
              to={t("routes.about")}
              className="callToActions animated reveal-text-up"
            >
              <span></span>
              <Translate langkey="home.hero.button" />
              <HiArrowNarrowRight />
            </Link>
          </div>
        </div>
      </main>

      <section className="about">
        <div className="about__container">
          <h2 className="animated reveal-text-up">
            <Translate langkey="home.about.container1.title" />
          </h2>

          <div className="about__container__text">
            <p className="animated reveal-text-up">
              <Translate langkey="home.about.container1.text1" />
            </p>

            <p className="animated reveal-text-up">
              <Translate langkey="home.about.container1.text2" />
            </p>

            <p className="animated reveal-text-up">
              <Translate langkey="home.about.container1.text3" />
            </p>

            <p className="animated reveal-text-up">
              <Translate langkey="home.about.container1.text4" />
            </p>

            <ul className="animated reveal-text-up">
              <li className="animated slide-up">JavaScript (ES6+)</li>

              <li className="animated slide-up">Html - (S)CSS</li>

              <li className="animated slide-up">React.js</li>

              <li className="animated slide-up">React Native</li>

              <li className="animated slide-up">Jest + Enzyme</li>

              <li className="animated slide-up">react-testing-library</li>

              <li className="animated slide-up">Tailwind CSS</li>

              <li className="animated slide-up">PWA's</li>

              <li className="animated slide-up">Webpack</li>

              <li className="animated slide-up">Three.js</li>

              <li className="animated slide-up">WebXR</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="works" ref={works}>
        {/* Works Header */}
        <div className="works__header animated slide-up">
          <div className="works__header__container">
            <h2 className="animated reveal-text-up">
              <Translate langkey="home.works.title" />
            </h2>
            <p className="animated reveal-text-up">
              <Translate langkey="home.works.subtitle" />
            </p>
          </div>
        </div>

        {/* List Projects */}
        <ul className="works__projects">
          {temporalWorks.map((item, i) => (
            <WorksLists
              key={item.id}
              index={i}
              item={item}
              data={temporalData}
              handleClick={handleClick}
            />
          ))}
        </ul>

        {/* See More Projects */}

        <div className="works__footer">
          <Link
            to={t("routes.works")}
            className="callToActions animated reveal-text-up"
          >
            <span></span>
            <Translate langkey="links.seeAllProjects" />
            <HiArrowNarrowRight />
          </Link>
        </div>
      </section>

      <section className="agencies">
        <div className="agencies__header">
          <div className="agencies__header__container">
            <h2 className="animated reveal-text-up">
              <Translate langkey="home.agencies.title" />
            </h2>
            <p className="animated reveal-text-up">
              <Translate langkey="home.agencies.subtitle" />
            </p>
          </div>
        </div>

        <div className="agencies__container">
          <img className="animated reveal-text-up" src={PortinosLogo} alt="" />
          <img className="animated reveal-text-up" src={IntelLogo} alt="" />
          <img className="animated reveal-text-up" src={KcumenLogo} alt="" />
          <img className="animated reveal-text-up" src={SenaLogo} alt="" />
          <img className="animated reveal-text-up" src={ArcorLogo} alt="" />
          <img className="animated reveal-text-up" src={OnuLogo} alt="" />
          <img className="animated reveal-text-up" src={HemavLogo} alt="" />
        </div>
      </section>
    </>
  );
};

export default Home;
